import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import CompanyLogos from './CompanyLogos'; 

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const reviews = [
    {
      name: "Kayla Strong, PhD",
      review: "Working with Yellow Ray Consulting has been an absolute pleasure. Their team is responsive, professional, and proactive, consistently improving processes and preventing issues before they arise. Their expertise is invaluable to my work.",
    }
    // {
    //   name: "John Smith",
    //   review: "The team provided excellent support and delivered a tailored software solution.",
    //   company: "Finance Experts Ltd.",
    // }
  ];

  useEffect(() => {
    if (isPopupOpen) { // Check if popup is open
      const emailUser = "info";
      const emailDomain = "yrcgi.com";
      const emailLink = document.getElementById("email-link");

      if (emailLink) {
        // Construct the email address and set it dynamically
        const email = `${emailUser}@${emailDomain}`;
        emailLink.setAttribute("href", `mailto:${email}`);
        emailLink.textContent = email;
      }
    }
  }, [isPopupOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000); // Change review every 5 seconds
    return () => clearInterval(interval);
  }, [reviews.length]);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <div className="home-container">
      <section id="hero">
        {/* <h2>Unlock the Power of Your Data with Custom Applications</h2> */}
        <h2>Unlock the Full Potential of Your Data with Tailored Solutions</h2>
        {/* <p>– From seamless data conversions to comprehensive reporting, we provide solutions that streamline your workflows and drive smarter, data-driven strategies for Business or Researchers.</p> */}
        <p>With a focus on seamless data conversions and comprehensive reporting, we leverage our skills to provide solutions that enhance your workflows and foster smarter, data-driven strategies for both businesses and researchers.</p>
        {/* Button to trigger popup form */}
        <button onClick={openPopup} style={buttonStyle}>
          Explore Your Options
        </button>
      </section>

      <section id="services">
        
        <div className="service-cards">

          <div className="card">
            <h3>Software Development and Customization</h3>
            <div className="service-details">
              <ul>
                <li>Delphi application development and maintenance</li>
                <li>Custom software solutions tailored to specific business needs</li>
                <li>Integration of third-party software and APIs</li>
              </ul>
            </div>
          </div>

          
          <div className="card">
            <h3>Data Analysis and Reporting</h3>
            <div className="service-details">
              <ul>
                <li>Statistical analysis using SPSS, SAS, and Stata</li>
                <li>Data visualization and reporting with Excel, OpenOffice Calc, and Delphi tools</li>
                <li>Custom data analysis and reporting solutions</li>
              </ul>
            </div>
          </div>

          <div className="card">
            <h3>Database Management and Optimization</h3>
            <div className="service-details">
              <ul>
                <li>PostgreSQL database design, implementation, and administration</li>
                <li>Database performance tuning and optimization</li>
                <li>Data migration and conversion services</li>
              </ul>
            </div>
          </div>


        <div className="card">
            {/* <h3>End to End Solutions</h3> */}
            <h3>Comprehensive Services</h3>
            <div className="service-details">
            <ul>
              <li>Server setup, deployment, and management</li>
              <li>Data modeling and architecture</li>
              <li>Full-cycle application development</li>
              {/* <li>API integrations and data flow</li>
              <li>System monitoring and optimization</li> */}
            </ul>
            </div>
        </div>
      </div>
    </section>

    {/* Reviews Section */}
    <section id="reviews" style={reviewsSectionStyle}>
        <h2 style={reviewHeaderStyle}>Hear From Those We've Worked With</h2>
        <div style={reviewCardStyle}>
          <p style={reviewTextStyle}>"{reviews[currentReviewIndex].review}"</p>
          <p style={reviewAuthorStyle}>
            - {reviews[currentReviewIndex].name} {reviews[currentReviewIndex].company}
          </p>
        </div>
      </section>

      <section id="Collaborators">
      <CompanyLogos /> 
      </section>

      {/* Popup Form */}
      {isPopupOpen && (
        <div style={overlayStyle}>
          <div style={popupStyle}>
            <h2 style={headerStyle}>Have a Question or Insight?</h2>
            <p style={taglineStyle}>We're here to help!</p>
            <label style={labelStyle}>
              Share some insight into your project or ask our team any questions you may have:
            </label>
            <div className="contact">
              <i className="fas fa-envelope"></i>
              <a id="email-link" className="email-link" style={linkStyle}>
                Contact Us
              </a>
            </div>
            <button onClick={closePopup} style={closeButtonStyle}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


//       {isPopupOpen && (
//         <div style={overlayStyle}>
//           <div style={popupStyle}>
//           <h2 style={headerStyle}>Have a Question or Insight?</h2>
//   <p style={taglineStyle}>We're here to help!</p>
//   <label style={labelStyle}>Share some insight into your project or ask our team any questions you may have:</label>
//   <div className="contact">
//     <i className="fas fa-envelope"></i>
//     <a id="email-link" className="email-link" style={linkStyle}>Contact Us</a>
//   </div>
//             {/* <h2 style={headerStyle}>How can we Help?</h2>
//             <form style={formStyle}>
//               <input type="text" style={inputStyle} placeholder="Name" />

//               <input type="email" style={inputStyle} placeholder="Email" />

//               <label style={labelStyle}>Share some insight into your project or ask our team any questions you may have:</label>
//               <textarea style={textareaStyle} placeholder="" />

//               <button type="submit" style={submitButtonStyle}>
//                 Submit
//               </button>
//             </form> */}
//             <button onClick={closePopup} style={closeButtonStyle}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
  
// );
// };



// Inline styles for the form and popup
const buttonStyle = {
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999, // Ensure the popup is on top
};

const popupStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  width: '500px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};


const taglineStyle = {
  marginBottom: '20px',
  fontSize: '16px',
  fontStyle: 'italic',
  color: '#555',
}; 

const headerStyle = {
  marginBottom: '20px',
  fontSize: '22px',
  fontWeight: 'bold',
};

// const formStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   fontWeight: 'bold',
//   textAlign: 'center',
//   gap: '15px',
//   alignItems: 'center',
// };

const linkStyle = {
  marginLeft: '8px',
  fontSize: '16px',
  color: '#007bff',
  textDecoration: 'underline',
  cursor: 'pointer',
};

const labelStyle = {
  textAlign: 'left',
  fontSize: '14px',
  marginBottom: '5px',
};

// Styles for the review section
const reviewsSectionStyle = {
  backgroundColor: '#f9f9f9',
  padding: '40px 20px',
  textAlign: 'center',
  marginTop: '20px',
};

const reviewHeaderStyle = {
  fontSize: '24px',
  marginBottom: '20px',
  color: '#333',
};

const reviewCardStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '10px',
  padding: '20px',
  maxWidth: '600px',
  margin: '0 auto',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
};

const reviewTextStyle = {
  fontSize: '18px',
  fontStyle: 'italic',
  marginBottom: '10px',
  color: '#555',
};

const reviewAuthorStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#333',
};

// const inputStyle = {
//   padding: '10px',
//   borderRadius: '5px',
//   border: '1px solid #ccc',
//   fontSize: '16px',
//   width: '90%',
// };

// const textareaStyle = {
//   padding: '10px',
//   borderRadius: '5px',
//   border: '1px solid #ccc',
//   fontSize: '16px',
//   width: '90%',
//   height: '100px',
// };

// const submitButtonStyle = {
//   padding: '10px 20px',
//   backgroundColor: '#28a745',
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   width: '80%',
//   fontSize: '16px',
// };

const closeButtonStyle = {
  marginTop: '15px',
  backgroundColor: 'transparent',
  color: '#007bff',
  border: 'none',
  cursor: 'pointer',
  fontSize: '14px',
};

export default Home;