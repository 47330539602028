import React from 'react';
import ronImg from '../Images/Ron_templeman.jpg';
import vladImg from '../Images/Vlad_tyshchenko.jpg';
import christineImg from '../Images/Christine_perrault.jpg';
//import kaylaImg from '../Images/Kayla strong.jpg';


const Company = () => {
    const styles = {
        section: {
          padding: '2rem 1rem',
          backgroundColor: '#f9f9f9',
        },
        container: {
          maxWidth: '900px',
          margin: '0 auto',
          textAlign: 'center',
        },
        heading: {
          fontSize: '2rem',
          marginBottom: '1.5rem',
          color: '#333',
        },
        card: {
          backgroundColor: '#fff',
          padding: '1.5rem',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          fontSize: '1rem',
          color: '#666',
          marginBottom: '2rem',
        },
        grid: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem',
          justifyContent: 'center',
        },
        teamCard: {
          backgroundColor: '#fff',
          padding: '1.5rem',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          maxWidth: '300px',
          flex: '1',
        },
        image: {
          width: '100%',
          height: 'auto',
          borderRadius: '50%',
          maxWidth: '150px',
          marginBottom: '1rem',
        },
        name: {
          fontSize: '1.25rem',
          margin: '0.5rem 0',
          color: '#333',
        },
        description: {
          fontSize: '0.9rem',
          color: '#666',
        },
      };
    
      const teamMembers = [
        {
          name: 'Ron Templeman',
          image: ronImg,
          description:
            'Ron Templeman, CEO and Director of Yellow Ray Consulting Group Inc., is a seasoned expert in statistical data analysis with a Bachelor of Science in Mathematics from the University of Saskatchewan. He combines strong analytical skills with a deep understanding of data management, making him a valuable asset to clients seeking insightful solutions. Additionally, Ron is committed to mentoring, helping clients and emerging professionals build confidence and expertise in data analysis and decision-making.',
        },
        {
          name: 'Vlad Tyshchenko',
          image: vladImg,
          description:
            'Vladyslav Tyshchenko is a dedicated team member at Yellow Ray Consulting Group Inc., holding a master’s degree in software engineering and a strong foundation as a full-stack developer. Since joining the team, Vladyslav has consistently demonstrated technical expertise and adaptability, contributing to our diverse projects and growing our technical capabilities.',
        },
        {
          name: 'Christine Perrault',
          image: christineImg,
          description:
            'Christine Perrault is an integral member of the Yellow Ray Consulting Group Inc. team, specializing in the management of Linux servers, PostgreSQL databases, and data backups. With a strong commitment to continuous learning, she also demonstrates her expertise in data application design and website creation, playing a key role in delivering innovative solutions for our clients',
        }
        // {
        //   name: ' Dr. Kayla Strong',
        //   image: kaylaImg,
        //   description:
        //     'Dr. Kayla Strong is a data scientist and epidemiologist specializing in veterinary medicine and various health-related fields.',
        // },
      ];
    
      return (
        <div style={styles.section}>
          {/* History Section */}
          <div style={styles.container}>
            <h2 style={styles.heading}>History</h2>
            <div style={styles.card}>
              <p>
              Since 1986, Yellow Ray Consulting Group Inc., formerly known as Optima Research Inc. and Templeman Consulting, has been a trusted partner in data-driven insights. Originally focused on statistical data analysis, we have evolved in response to our clients' growing needs. Over the past three decades, our expertise has expanded to include comprehensive data management, data governance, data stewardship, and broader information management services. Today, Yellow Ray provides end-to-end solutions that empower organizations to harness the full potential of their data with precision and integrity
              </p>
            </div>
          </div>
    
          {/* Our Team Section */}
          <div style={styles.container}>
            <h2 style={styles.heading}>Our Team</h2>
            <div style={styles.grid}>
              {teamMembers.map((member, index) => (
                <div style={styles.teamCard} key={index}>
                  <img src={member.image} alt={member.name} style={styles.image} />
                  <h3 style={styles.name}>{member.name}</h3>
                  <p style={styles.description}>{member.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };
    


export default Company;